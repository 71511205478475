import styles from './Card.module.scss';
import * as FormatHelper from '@/src/helpers/FormatHelper';
import useTicker from '@/src/hooks/useTicker';
import catalogStyles from '@/components/catalog/Catalog.module.scss';
import Icon from '@/components/icon/Icon';
import { useRef } from 'react';
import Link from 'next/link';
import useClientOnly from '@/src/hooks/useClientOnly';

/**
 * @param {Schedule} model
 * @param cardClasses
 */
export default function ScheduleCard({ model, cardClasses }) {
    const isClient = useClientOnly();
    const [enterHandler, leaveHandler, bodyCoverRef, tickerElementRef] = useTicker();
    const scheduleRef = useRef(null);

    return (
        <article
            ref={scheduleRef}
            className={catalogStyles['cover'] + ' ' + styles['cover'] + ` cover--shows ${cardClasses}`}
            aria-label={model.getProgramTitle()}
            onMouseEnter={enterHandler}
            onMouseLeave={leaveHandler}
        >
            <Link
                className={'cover__wrapper cover__wrapper--int'}
                href={model.getUrl()}
                aria-label={model.getProgramTitle()}
            >
                <div className={styles['cover__poster']}>
                    <div className="cover__skeleton cover__skeleton--poster" />
                    <img
                        className={styles['cover__image'] + ' ' + 'lazyload'}
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={model.getImageSrc()}
                        aria-label={model.title}
                        alt={model.title}
                    />
                    {isClient && model.isCatchup() && (
                        <div className="cover__sticker cover__sticker--return">
                            <Icon id={'catch-up'} modifier={'icon--catch-up cover__sticker-icon'} />
                        </div>
                    )}
                    <Icon id={`age-${model.age}`} modifier={'cover__age'} />
                    {isClient && model.getCompletePercent() && (
                        <div className={styles['cover__progress-wrapper']}>
                            <div
                                className={styles['cover__progress']}
                                style={{ width: model.getCompletePercent() + '%' }}
                            />
                        </div>
                    )}
                </div>
                <div className={styles['cover__body']}>
                    <div className={styles['cover__content']}>
                        <div className="cover__label-wrapper cover__label-wrapper--name" ref={bodyCoverRef}>
                            <div className={'cover__label'} ref={tickerElementRef}>
                                {model.getProgramTitle()}
                            </div>
                        </div>
                    </div>
                    <div className="cover__label-wrapper">
                        <div className="cover__label-sub">
                            <div className="cover__label-channel">{model.getChannelTitle()}</div>
                            <div className="cover__label-time" suppressHydrationWarning={true}>
                                <ShortPeriod date={model.startAt} />
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </article>
    );
}

function ShortPeriod({ date }) {
    return FormatHelper.getShortPeriod(date);
}
