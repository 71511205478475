import React from 'react';
import { useAuthStore } from '@/src/stores/RootStoreProvider';
import PinChecker from '@/src/checkers/PinChecker';
import AuthChecker from '@/src/checkers/AuthChecker';

export default function AuthPinChecker({
    children,
    checked = true,
    forceAuthCheck = true,
    capturedEventType = 'onClick',
    title,
    backgroundSrc,
    onBeforeCheck,
}) {
    const { isGuest } = useAuthStore();

    return isGuest && forceAuthCheck ? (
        <AuthChecker title={title} backgroundSrc={backgroundSrc} onBeforeCheck={onBeforeCheck}>
            {children}
        </AuthChecker>
    ) : (
        <PinChecker checked={checked} capturedEventType={capturedEventType} onBeforeCheck={onBeforeCheck}>
            {children}
        </PinChecker>
    );
}
