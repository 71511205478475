import styles from './Card.module.scss';
import catalogStyles from 'src/components/catalog/Catalog.module.scss';
import useTicker from '@/src/hooks/useTicker';
import Icon from '@/components/icon/Icon';
import { ScoresTypes } from '@/src/api/v3/models/Score';
import CardBadge from '@/components/v3/cards/common/CardBadge';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import { observer } from 'mobx-react';
import Link from 'next/link';
import PinChecker from '@/src/checkers/PinChecker';

/**
 * @param {Asset} model
 * @param cardClasses
 */
function AssetCard({ model, cardClasses }) {
    const [enterHandler, leaveHandler, bodyCoverRef, tickerElementRef] = useTicker();
    const { pinStatus, checkIsAdultSession } = usePinStore();

    let classNames = [];
    classNames.push(cardClasses);
    if (model.isAvailable()) {
        classNames.push(styles['cover--icon']);
    }
    /** @type Subscription */
    const subscription = model.offers.getSubscription();
    const pinReason = () => {
        return model.getPinReason(pinStatus, false, false, checkIsAdultSession());
    };
    return (
        <article
            className={
                catalogStyles['cover'] +
                ' ' +
                styles['cover'] +
                ' ' +
                styles['cover--movies'] +
                ' ' +
                classNames.join(' ')
            }
            onMouseEnter={enterHandler}
            onMouseLeave={leaveHandler}
        >
            <PinChecker checked={pinReason}>
                <Link href={model.getUrl()} className={'cover__wrapper cover__wrapper--int'} aria-label={model.title}>
                    <div className={styles['cover__poster']}>
                        <div className="cover__skeleton cover__skeleton--poster" />
                        {pinReason() ? (
                            <div className="cover__no-image">
                                <Icon id={'parental'} modifier={'icon--parental cover__no-image-icon'} />
                            </div>
                        ) : (
                            <img
                                className={styles['cover__image'] + ' ' + 'lazyload'}
                                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                                data-src={model.getImageSrc()}
                                aria-label={model.title}
                                alt={model.title}
                            />
                        )}
                        <Icon id={`age-${model.age}`} modifier={'icon--age cover__age'} />
                        {model.isFavorite && (
                            <div className="cover__sticker cover__sticker--fav">
                                <Icon id={'love'} modifier={'icon--love cover__sticker-icon'} />
                            </div>
                        )}
                        {model.viewable.isProgress() && (
                            <div className="cover__progress-wrapper cover__progress-wrapper--age-offset">
                                <div
                                    className="cover__progress"
                                    style={{ width: model.viewable.getProgress() + '%' }}
                                />
                            </div>
                        )}
                        {subscription?.getBadgeSrc() && (
                            <div className="cover__brand-wrapper">
                                <img
                                    className="cover__brand"
                                    src={subscription.getBadgeSrc()}
                                    alt={subscription.title}
                                    width={90}
                                    height={26}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles['cover__body']}>
                        <div className={styles['cover__content']}>
                            <div className="cover__label-wrapper cover__label-wrapper--name" ref={bodyCoverRef}>
                                <div className={'cover__label'} ref={tickerElementRef}>
                                    {model.title}
                                </div>
                            </div>
                        </div>
                        {Object.keys(model.scores).length > 0 && <Ratings scores={model.scores} />}
                        <div className="cover__badges">
                            <CardBadge model={model} offers={model.offers} />
                        </div>
                    </div>
                </Link>
            </PinChecker>
        </article>
    );
}

function Ratings({ scores }) {
    return (
        <div className="cover__ratings">
            {scores[ScoresTypes.SCORE_KINOPOISK] && (
                <div className="cover__ratings-item">
                    <img
                        className="cover__ratings-icon"
                        src="/assets/img/kp.svg"
                        alt="КиноПоиск"
                        width="14"
                        height="15"
                    />
                    <div className="cover__ratings-value">{scores[ScoresTypes.SCORE_KINOPOISK].toFixed(2)}</div>
                </div>
            )}
            {scores[ScoresTypes.SCORE_IMDB] && (
                <div className="cover__ratings-item">
                    <img className="cover__ratings-icon" src="/assets/img/imdb.svg" alt="IMDb" width="27" height="13" />
                    <div className="cover__ratings-value">{scores[ScoresTypes.SCORE_IMDB].toFixed(2)}</div>
                </div>
            )}
        </div>
    );
}

export default observer(AssetCard);
