import styles from '@/components/v3/cards/Card.module.scss';
import { SwiperSlide } from 'swiper/react';
import DummyCard from '@/components/v3/cards/DummyCard';
import CollectionCard from '@/components/v3/cards/CollectionCard';
import Asset from '@/src/api/v3/models/Asset';
import AssetCard from '@/components/v3/cards/AssetCard';
import Schedule from '@/src/api/v3/models/Schedule';
import ScheduleCard from '@/components/v3/cards/ScheduleCard';
import Season from '@/src/api/v3/models/Season';
import Episode from '@/src/api/v3/models/Episode';
import EpisodeCard from '@/components/v3/cards/EpisodeCard';
import Channel from '@/src/api/v3/models/Channel';
import ChannelCard from '@/components/v3/cards/ChannelCard';
import Subscription from '@/src/api/v3/models/Subscription';
import SubscriptionCard from '@/components/v3/cards/SubscriptionCard';
import Banner from '@/src/api/v3/models/Banner';
import BannerCard from '@/components/v3/cards/BannerCard';
import Genre from '@/src/api/v3/models/Genre';
import Compilation from '@/src/api/v3/models/Compilation';
import { gtmEvent } from '@/src/modules/events/GTM';

/**
 * @param {BaseModel[]} items
 * @param {string} cardClasses
 * @param {string} divClass
 * @param {Object} eventParams
 */
export default function Cards({ items, cardClasses = '', divClass = '', eventParams = {} }) {
    if (!items.length) {
        return '';
    }
    /**
     * @param {BaseModel} item
     */
    const onClick = (item) => {
        if (item.EVENT_ITEM_CATEGORY) {
            gtmEvent('showcase', 'item_click', {
                item_id: item.id,
                item_name: item.title,
                item_category: item.EVENT_ITEM_CATEGORY,
                ...eventParams,
            });
        }
    };
    return divClass
        ? items.map((item) => {
              return (
                  <SwiperSlide className={divClass} key={item.uniqueId} onClick={() => onClick(item)}>
                      <Card item={item} cardClasses={cardClasses} />
                  </SwiperSlide>
              );
          })
        : items.map((item) => {
              return <Card item={item} cardClasses={cardClasses} key={item.uniqueId} />;
          });
}

/**
 * @param Number count
 * @param cardClasses
 * @param divClass
 */
export function DummyCards({ count, cardClasses = '', divClass = '' }) {
    return divClass
        ? [...Array(count)].map((_, index) => (
              <SwiperSlide className={divClass} key={'dummy-' + index /* NOSONAR */}>
                  <DummyCard cardClasses={cardClasses} />
              </SwiperSlide>
          ))
        : [...Array(count)].map((_, index) => (
              <DummyCard cardClasses={cardClasses} key={'dummy-' + index /* NOSONAR */} />
          ));
}

export function getSliderStubClass(item, defaultClass = 'cover--packages') {
    return styles[item?.SLIDER_STUB_CLASS || defaultClass];
}

function Card({ item, cardClasses = '' }) {
    if (item instanceof Asset) {
        return <AssetCard model={item} cardClasses={cardClasses} />;
    }
    if (item instanceof Schedule) {
        return <ScheduleCard model={item} cardClasses={cardClasses} />;
    }
    if (item instanceof Episode) {
        return <EpisodeCard model={item} cardClasses={cardClasses} />;
    }
    if (item instanceof Season) {
        return <CollectionCard model={item} style={getSliderStubClass(item)} />;
    }
    if (item instanceof Channel) {
        return <ChannelCard model={item} cardClasses={cardClasses} />;
    }
    if (item instanceof Subscription) {
        return <SubscriptionCard model={item} cardClasses={cardClasses} />;
    }
    if (item instanceof Banner) {
        return <BannerCard model={item} />;
    }
    if (item instanceof Genre || item instanceof Compilation) {
        return <CollectionCard model={item} showTitle={false} />;
    }
    return <CollectionCard model={item} />;
}
