import styles from './Card.module.scss';
import catalogStyles from '@/components/catalog/Catalog.module.scss';
import Icon from '@/components/icon/Icon';
import CardBadge from '@/components/v3/cards/common/CardBadge';
import Link from 'next/link';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import PinChecker from '@/src/checkers/PinChecker';
import { observer } from 'mobx-react';

/**
 * @param {Subscription} model
 * @param cardClasses
 */
function SubscriptionCard({ model, cardClasses }) {
    const { pinStatus, checkIsAdultSession } = usePinStore();
    const pinReason = () => {
        return model.getPinReason(pinStatus, false, false, checkIsAdultSession());
    };
    return (
        <article className={catalogStyles['cover'] + ' ' + styles['cover'] + ' cover--packages ' + cardClasses}>
            <PinChecker checked={pinReason}>
                <Link className={'cover__wrapper cover__wrapper--int'} href={model.getUrl()} aria-label={model.title}>
                    <div className="cover__poster">
                        <div className="cover__skeleton cover__skeleton--poster" />
                        <img
                            className="cover__image lazyload"
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={model.getImageSrc()}
                            aria-label={model.title}
                            alt={model.title}
                        />
                    </div>
                    <div className="cover__body">
                        <div className="cover__content">
                            {!model.isAvailable() && <Icon id={'lock'} modifier={'icon--lock cover__icon'} />}
                            <div className="cover__label-wrapper cover__label-wrapper--name">
                                <div className="cover__label">{model.title}</div>
                            </div>
                        </div>
                        <div className="cover__badges">
                            <CardBadge model={model} offers={model.offers} />
                        </div>
                    </div>
                </Link>
            </PinChecker>
        </article>
    );
}

export default observer(SubscriptionCard);
