import styles from './Card.module.scss';
import catalogStyles from '@/components/catalog/Catalog.module.scss';

/**
 * @param cardClasses
 */
export default function DummyCard({ cardClasses }) {
    return (
        <article className={catalogStyles['cover'] + ' ' + styles['cover'] + ' ' + cardClasses}>
            <a className={styles['cover__wrapper']} href={'#'}>
                <div className={styles['cover__poster']}>
                    <div className="cover__skeleton cover__skeleton--poster" />
                    <div className="cover__image" />
                </div>
                <div className={styles['cover__body']} />
            </a>
        </article>
    );
}
