import styles from './Card.module.scss';
import catalogStyles from '@/components/catalog/Catalog.module.scss';
import Link from 'next/link';

/**
 * @param {Banner} model
 * @param style
 */
export default function BannerCard({ model, style }) {
    return (
        <article
            className={catalogStyles['cover'] + ' ' + styles['cover'] + ' ' + (style || styles['cover--packages'])}
        >
            <Link className={'cover__wrapper cover__wrapper--int'} href={model.getUrl()}>
                <div className="cover__poster">
                    <img
                        className={styles['cover__image'] + ' ' + 'lazyload'}
                        src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                        data-src={model.getImageSrc()}
                        aria-label={model.title}
                        alt={model.title}
                    />
                </div>
                <div className="cover__body">
                    <div className="cover__description">{model.description}</div>
                </div>
            </Link>
        </article>
    );
}
