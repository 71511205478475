import { useEffect, useState } from 'react';

/**
 * Возвращает true если происходит рендер на стороне клиента
 * @returns {Boolean}
 */
export default function useClientOnly() {
    const [value, setValue] = useState(false);

    useEffect(() => {
        setValue(true);
    }, []);

    return value;
}
