import { createContext, useContext, useMemo } from 'react';

const RootModelContext = createContext(undefined);

export function RootModelProvider({ children, rootModel, rootShowcase, onShowcaseUpdate }) {
    const value = useMemo(
        () => ({ rootModel, rootShowcase, onShowcaseUpdate }),
        [onShowcaseUpdate, rootModel, rootShowcase]
    );
    return <RootModelContext.Provider value={value}>{children}</RootModelContext.Provider>;
}

/**
 * @returns {{rootModel:BaseModel, rootShowcase:Showcase, Function:onShowcaseUpdate}}
 */
export const useRootModel = () => {
    return useContext(RootModelContext);
};
