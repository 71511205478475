import styles from './Card.module.scss';
import useTicker from '@/src/hooks/useTicker';
import { useRef } from 'react';
import useModals from '@/src/hooks/useModals';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import AuthPinChecker from '@/src/checkers/AuthPinChecker';
import useForceUpdate from '@/src/hooks/useForceUpdate';
import { useRootModel } from '@/src/hooks/useRootModel';
import { useRouter } from 'next/router';
import keyEnterCheck from '@/src/helpers/MiscHelper';

/**
 * @param {Episode} model
 * @param cardClasses
 */
export default function EpisodeCard({ model, cardClasses }) {
    const { openModal } = useModals();
    const { pinStatus, checkIsPinExpired } = usePinStore();
    const [enterHandler, leaveHandler, bodyCoverRef, tickerElementRef] = useTicker();
    const episodeRef = useRef(null);
    const forceUpdate = useForceUpdate();
    const { rootModel } = useRootModel() || {};
    const router = useRouter();

    const openPlayer = async (asset) => {
        const ModalPlayer = (await import('@/components/modal/player/ModalPlayer')).default;
        openModal(
            <ModalPlayer
                asset={asset}
                rootModel={rootModel}
                setViewableStatus={setViewableStatus}
                setAsset={openPlayer}
            />,
            false
        );
    };

    const onClick = () => {
        if (model.isAvailable()) {
            if (rootModel?.offers?.getCheapestOffer()?.subscription?.isPartner()) {
                window.open(model.shareLink || rootModel.shareLink, '_blank');
            } else {
                // noinspection JSIgnoredPromiseFromCall
                openPlayer(model);
            }
        } else if (rootModel) {
            const details =
                document.getElementsByClassName('details__offers')[0] ||
                document.getElementsByClassName('details__actions-item')[0];
            const button = details?.getElementsByTagName('button')[0];
            if (button) {
                button.click();
            }
        } else {
            const series = model.series;
            if (series) {
                // noinspection JSIgnoredPromiseFromCall
                router.push(series.getUrl());
            }
        }
    };

    const setViewableStatus = (position) => {
        model.viewable.position = position;
        forceUpdate();
    };

    const getViewPinReason = () => {
        return (
            model.isAvailable() &&
            (typeof window === 'undefined' || Boolean(!document.fullscreenElement)) &&
            model.getViewPinReason(pinStatus, checkIsPinExpired())
        );
    };

    return (
        <AuthPinChecker checked={getViewPinReason} forceAuthCheck={false}>
            <article
                ref={episodeRef}
                onClick={onClick}
                onKeyDown={keyEnterCheck(onClick)}
                className={`cover cover--episodes action ${cardClasses}`}
                aria-label={model.title}
                onMouseEnter={enterHandler}
                onMouseLeave={leaveHandler}
            >
                <div className={'cover__wrapper cover__wrapper--int'} aria-label={model.title}>
                    <div className={styles['cover__poster']}>
                        <div className="cover__skeleton cover__skeleton--poster" />
                        <img
                            className={styles['cover__image'] + ' ' + 'lazyload'}
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={model.getImageSrc()}
                            aria-label={model.title}
                            alt={model.title}
                        />
                        <div className={styles['cover__badges']} />
                        {model.viewable.isProgress() && (
                            <div className={styles['cover__progress-wrapper']}>
                                <div
                                    className={styles['cover__progress']}
                                    style={{ width: model.viewable.getProgress() + '%' }}
                                />
                            </div>
                        )}
                    </div>
                    <div className={styles['cover__body']} ref={bodyCoverRef}>
                        <div className={styles['cover__content']}>
                            <div className="cover__label-wrapper cover__label-wrapper--name" ref={bodyCoverRef}>
                                <div className={'cover__label'} ref={tickerElementRef}>
                                    {model.title}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        </AuthPinChecker>
    );
}
