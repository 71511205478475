import styles from './Card.module.scss';
import catalogStyles from '@/components/catalog/Catalog.module.scss';
import Link from 'next/link';
import { usePinStore } from '@/src/stores/RootStoreProvider';
import PinChecker from '@/src/checkers/PinChecker';
import { observer } from 'mobx-react';

/**
 * @param {BaseModel} model
 * @param {string} style
 * @param {Boolean} showTitle
 */
function CollectionCard({ model, style, showTitle = true }) {
    const { pinStatus, checkIsAdultSession } = usePinStore();
    const pinReason = () => {
        return model.getPinReason(pinStatus, false, false, checkIsAdultSession());
    };
    return (
        <article
            className={catalogStyles['cover'] + ' ' + styles['cover'] + ' ' + (style || styles['cover--packages'])}
            aria-label={model.title}
        >
            <PinChecker checked={pinReason}>
                <Link className={'cover__wrapper cover__wrapper--int'} href={model.getUrl()} aria-label={model.title}>
                    <div className={styles['cover__poster']}>
                        <div className="cover__skeleton cover__skeleton--poster" />
                        <img
                            className={styles['cover__image'] + ' ' + 'lazyload'}
                            src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                            data-src={model.getImageSrc()}
                            aria-label={model.title}
                            alt={model.title}
                        />
                        <div className={styles['cover__badges']} />
                    </div>
                    {showTitle && (
                        <div className={styles['cover__body']}>
                            <div className={styles['cover__content']}>
                                <div className={styles['cover__label']}>{model.title}</div>
                            </div>
                        </div>
                    )}
                </Link>
            </PinChecker>
        </article>
    );
}

export default observer(CollectionCard);
